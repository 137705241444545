<template>
    <div class="main">
        <v-layout style="margin-top: 10px;">
            <v-data-table
                :headers="dataTablesHeadersRendered"
                :items="dataTablesItems"
                :options.sync="options"
                hide-default-footer
                :loading="dataTableLoading"
            >
                <template v-slot:top>
                    <v-flex  style="padding-top: 10px;">
                        <div>
                            <div style="display: flex; height: 66px; padding-top: 0px; padding-bottom: 0px; padding-left: 0px;" class="user-list-status-select-wrapper">
                                <!-- search field -->
                                <v-text-field
                                    v-model="search"
                                    label="Search"
                                    style="height: 66px;"
                                    clearable
                                    append-icon="mdi-search"
                                    class="user-list-search-field"
                                ></v-text-field>
                                
                                <!-- status select -->
                                <v-select
                                    v-model="selectedStatus"
                                    :items="statuses"
                                    label="Status"
                                    class="user-list-status-select-field"
                                    @change="updatePageData(), updateUsersCacheStore()"
                                ></v-select>

                                <!-- roles select -->
                                <!-- <v-select
                                    v-model="roleSelected"
                                    :items="roles"
                                    label="Role"
                                    item-value="id"
                                    item-text="name"
                                    class="user-list-status-select-field"
                                    @change="updatePageData"
                                ></v-select> -->

                                <!-- data sharing select -->
                                <v-select
                                    v-model="dataSharingRoleSelected"
                                    :items="dataSharingRoles"
                                    label="Data access"
                                    item-value="id"
                                    item-text="name"
                                    class="user-list-status-select-field"
                                    @change="updatePageData(), updateUsersCacheStore()"
                                ></v-select>

                                <!-- modify columns dialog trigger button -->
                                <v-btn
                                    style="margin-top: 10px;"
                                    color="primary"
                                    @click="openModifyDataTableColumnsDialog"
                                    small
                                    fab
                                > 
                                    <v-icon>mdi-cog</v-icon> 
                                </v-btn>
                            </div>
                        </div>
                    </v-flex>
                </template>

                <template v-slot:[`item.options`]="{ item }">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-dots-vertical
                            </v-icon>
                        </template>

                        <v-list style="min-width: 250px;">
                            <div
                                v-for="(group, groupIndex) in item.options"
                                :key="groupIndex"
                            >
                                <v-list-item v-for="(groupItem, groupItemKey) in group" :key="groupItemKey" class="worker-document-menu-option" :disabled="groupItem.disabled">
                                    <v-flex :style="groupItem.style" @click="() => { groupItem.action(item); }">
                                        <v-icon :style="groupItem.style"> {{ groupItem.icon }} </v-icon>
                                        {{ groupItem.title }}
                                    </v-flex>
                                </v-list-item>
                                <v-divider v-if="groupIndex < item.options.length - 1" style="border-bottom: 1px solid lightgray;"></v-divider>
                            </div>
                        </v-list>
                    </v-menu>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                        :color="getColor(item.status, item.blocked, item)"
                        dark
                    >
                        {{ item.statusText }}
                    </v-chip>
                </template>

                <template v-slot:[`item.emailStatus`]="{ item }">               
                    <!-- <v-tooltip v-if="item.emailError != null" left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                :color="getEmailColor(item.emailStatus)"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ item.emailStatusText }}
                            </v-chip>
                        </template>
                        <span> {{item.emailError}} </span>
                    </v-tooltip> -->

                    <v-chip 
                        :color="getEmailColor(item.emailStatus)"
                        dark
                    >
                        {{ item.emailStatusText }}
                    </v-chip>
                </template>

                <template v-slot:[`item.worker`]="{ item }">
                    <v-icon v-if="item.worker == null" color="error"> mdi-close </v-icon>
                    <v-icon v-else color="primary"> mdi-check </v-icon>
                </template>

                <template v-slot:footer>
                    <div class="display: inline-block;">
                        <TableFooter
                            :itemsPerPageOptions="itemsPerPageOptions"
                            :pageNumber="options.page"
                            :itemsPerPageNumber="options.itemsPerPage"
                            @update-page-number="updatePageNumber"
                            @update-page-size="updatePageSize"
                            :totalItemsNumber="serverSideTotalItemsNumber"
                        ></TableFooter>
                    </div>
                </template>
            </v-data-table>

            <v-dialog
                v-model="modifyDataTablesColumnsDialog"
                width="350"
                persistent
            >
                <v-card>
                    <v-card-title style="font-size: 18px;">Choose column</v-card-title>
                    <v-list>
                        <v-list-item v-for="(option, index) in dataTableHeadersSelectItems" :key="index">
                            <v-checkbox
                                v-model="dataTableHeadersSelected"
                                :label="option.text"
                                :value="option.value"
                                style="padding:0; margin:0;"
                            ></v-checkbox>
                        </v-list-item>
                    </v-list>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" class="align-self-center" style="margin-bottom: 15px;" @click="() => { modifyDataTablesColumnsDialog = false; }">Cancel</v-btn>
                        <v-btn text color="primary" class="align-self-center" style="margin-bottom: 15px;" @click="() => { updateDataTablesColumns(); modifyDataTablesColumnsDialog = false; }" :disabled="dataTableHeadersSelected.length == 1">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <UserDialog
                ref="userDialog"
                :userId="userDialogUserId"
                :userData="userDialogUserData"
                :roles="userRolesData"
                :dataAccess="dataAccessData"
                @refresh-data="updatePageData"
                @show-overlayer="showOverlayer"
                @hide-overlayer="hideOverlayer"
            ></UserDialog>

            <!-- add new user button -->
            <v-btn
                color="primary"
                fab
                v-if="canUserAddNewUsers"
                @click="createNewUser"
                class="add-new-users-button"
            >
                <v-icon>
                    mdi-plus 
                </v-icon>
            </v-btn>

            <!-- user set new value for 'is_active' confirmation dialog -->
            <v-dialog 
                persistent 
                v-model="userActiveStatusChangeDialog.visible"
                max-width="320"
            >
                <v-card>
                    <v-card-title class="headline text-h5">{{ userActiveStatusChangeDialog.title }}</v-card-title>

                    <v-card-text style="text-align:justify">{{ userActiveStatusChangeDialog.content }}</v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="grey darken-1"
                            text
                            @click="() => { userActiveStatusChangeDialog.visible = false; }">
                            Cancel
                        </v-btn>

                        <v-btn color="green darken-1"
                            text
                            @click="changeUserActiveStatus">
                            Yes
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- delete user dialog -->
            <v-dialog
                v-model="deleteUserDialog.visible"
                width="350px"
                persistent
            >
                <v-card >
                    <v-card-title>
                        <span> {{ lviews.deleteUserTitle }} </span>
                    </v-card-title>

                    <v-card-text>
                        <span> {{ lviews.deleteUserContent }} </span>
                        <strong> {{ deleteUserDialog.userName }}</strong> ?
                    </v-card-text>
                    
                    <v-progress-linear
                        v-if="deleteUserDialog.loading"
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="deleteUserCancel" text>
                            Cancel
                        </v-btn>
                        <v-btn @click="deleteUserSave" color="red" text>
                        <!-- <v-btn color="red" text> -->
                            <strong> Delete</strong>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
			v-model="errorDialog.model"
			width="300"
		>
			<v-card>
				<v-card-title>
					{{ errorDialog.errorTitle }}
				</v-card-title>

				<v-card-text>
					{{ errorDialog.errorMessage }}
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="errorDialog.model = false"
				>
					OK
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
        </v-layout>
    </div>
</template>

<script>
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'
//import TableFooter from './TableFooter.vue'
const TableFooter = () => import('./TableFooter.vue');
//import UserDialog from './UserDialog.vue'
const UserDialog = () => import('./UserDialog.vue');

export default {
    name: 'UserList',

	components: {
		TableFooter,
		UserDialog
	},

    data() {
        return{
            permissions: {
                add: true,
                view: true,
                delete: true,
                change: true
            },
            top: false,
            right: true,
            bottom: true,
            left: false,
            dialog: false,
            dialogDelete: false,
            deleteUserDialog: {
                visible: false,
                id: null,
                userName: null,
                loading: false,
            },
            errorDialog: {
				errorTitle: "Error occured",
				errorMessage: "An error occured",
				model: false
			},
            options: {},
            search: '',
            dataTablesHeaders:[
                { id: 0, text: 'Full name', align: 'start', sortable: true, value: 'name', visibleByDefault: true, },
                { id: 1, text: 'Birth date', sortable: false, value: 'birthDate', visibleByDefault: true, },
                { id: 2, text: 'External ID', sortable: true, value: 'externalId', visibleByDefault: false, },
                { id: 3, text: 'Role', sortable: false, value: 'role', visibleByDefault: true, },                
                { id: 4, text: 'Data access', sortable: false, value: 'dataAccess', visibleByDefault: true, },
                { id: 5, text: 'Status', sortable: false, value: 'status', visibleByDefault: true, },
                { id: 6, text: 'Last login', sortable: false, value: 'lastLogin', visibleByDefault: true, },
                { id: 7, text: 'Login', sortable: false, value: 'login', visibleByDefault: true, },
                { id: 8, text: 'Email', sortable: true, value: 'email', visibleByDefault: false, },
                { id: 9, text: 'Email status', sortable: false, value: 'emailStatus', visibleByDefault: true, },
                { id: 10, text: 'Worker', sortable: false, value: 'worker', visibleByDefault: true, },
                { id: 11, text: '', value: 'options', sortable: false, class:"data-tables-header-field", visibleByDefault: true }
            ],
            dataTablesItems:[
                /*{
                    name: 'Kowalski Jan',
                    birthDate: '17.10.1982',
                    role: 'Administrator',
                    rolesIds [{Number}, ...],
                    dataAccess: 'Wszystkie rekordy',
                    status: 'Aktywny',
                    lastLogin: '01.07.2021',
                    options:[
                        [
                            { 
                                icon: {String}, 
                                title: "{String}", 
                                action: {Function} 
                            }
                        ],
                    ]
                }*/
            ],
            dataTablesItemsSourceData: [
                // User Profile serialized data
            ],

            dataTableHeadersSelectItems: [
                /**
                 * {
                 *      value: {Integer},
                 *      text: {String}
                 * }
                 */
                {
                    value: 0,
                    text: "Full name" // Nazwisko i imię
                },
                {
                    value: 1,
                    text: "Birth date" // Data urodzenia
                },
                {
                    value: 2,
                    text: "External ID"
                },
                {
                    value: 3,
                    text: "Role" // Rola
                },
                {
                    value: 4,
                    text: "Data access", // Dostęp do danych
                },
                {
                    value: 5,
                    text: "Status" // Status
                },
                {
                    value: 6,
                    text: "Last login" // Ostatnio zalogowany
                },
                {
                    value: 7,
                    text: "Login" // Nazwa użytkownika
                },
                {
                    value: 8,
                    text: "Email"
                },
                {
                    value: 9,
                    text: "Email status" // Status maila
                },
                {
                    value: 10,
                    text: "Worker" // Pracownik
                },
            ],
            modifyDataTablesColumnsDialog: false,
            dataTablesHeadersRendered: [],
            dataTableHeadersSelected: [],
            fab: false,
            statuses: [
                {
                    text: "All",
                    value: -1
                },
                {
                    text: "Active",
                    value: 1
                },
                {
                    text: "Inactive",
                    value: 0
                },
                {
                    text: "Blocked",
                    value: 2
                },
            ],
            selectedStatus: -1,

            roles: [],
            roleSelected: -1,

            dataSharingRoles: [],
            dataSharingRoleSelected: -1,

            screenWidth:null,

            itemsPerPageOptions: [5, 10, 15, 30, 50, 100, 500],
			page: 1,
			itemsPerPage: 10,
			// itemsPerPageArray: [5, 10, 50, 100],
			totalItems: 0,
            serverSideTotalItemsNumber: 0,

            preventUpdate: false,
            searchTimeout: null,

            // active/inactive status
            activeUsersNumber: 0,
            inactiveUsersNumber: 0,

            administratorsNumber: 0,
            usersNumber: 0,

            userDialogUserId: null,
            userDialogUserData: {},

            userRolesData: [],
            dataAccessData: [],
            
            // user active status dialog data
            userActiveStatusChangeDialog: {
                visible: false,
                title: "",
                content: "",
                user: null,
            },

            dataTableLoading: false,
        };
    },
    beforeMount() {
        this.screenWidth = window.innerWidth;
    },
    mounted: async function() {
        if(!localStorage.getItem('tenantSlug')){
			this.$router.push('/choose-tenant');
            return
		}
        this.$emit("set-state", "default");
        this.$emit('set-title', this.lviews.userListTitle);
        this.$emit('getGoBackLink', "");

        //setting permissions
        let userPermissions = []
        if(localStorage.getItem('permissions') !== null){
			userPermissions = JSON.parse(localStorage.permissions);
		}

        if (!userPermissions.includes("admin_view")) {
            this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
            return
		}

        if (!userPermissions.includes("admin_add")) {
			this.permissions.add = false
		}

		if (!userPermissions.includes("admin_change")) {
			this.permissions.change = false
		} 

		if (!userPermissions.includes("admin_delete")) {
			this.permissions.delete = false
		}
        this.preventUpdate = true;
        this.dataSharingRoleSelected=this.$store.state.usersCache.dataAccess
		this.search=this.$store.state.usersCache.search
		this.selectedStatus = this.$store.state.usersCache.status
		this.options.itemsPerPage=this.$store.state.usersCache.itemsPerPage
		// this.options.page=this.$store.state.usersCache.page
        this.dataTableHeadersSelected=this.$store.state.usersCache.columnsVisible
        
        this.dataTablesHeadersRendered = [];
        if(this.dataTableHeadersSelected && this.dataTableHeadersSelected.length>0){
            for(let i = 0; i < this.dataTableHeadersSelected.length; i++){
                this.dataTablesHeadersRendered.push(
                    this.dataTablesHeaders[ this.dataTableHeadersSelected[i] ]
                );
            }
        }
        else{
            this.dataTableHeadersSelected=[]
            for(let i = 0; i < this.dataTablesHeaders.length; i++){
                if (this.dataTablesHeaders[i].visibleByDefault) {
                    this.dataTablesHeadersRendered.push(this.dataTablesHeaders[i]);
                    this.dataTableHeadersSelected.push(i);
                }
            }
        }
        window.addEventListener('resize', ()=>{
            this.screenWidth = window.innerWidth;
        });

    },
    computed: {
        canUserAddNewUsers: {
            get: function() {
                // let userPermissions = localStorage.getItem("user_permissions");
                // if (!userPermissions.includes("fob.admin_add")) {
                //     return false;
                // }
                return true;
            }
        },
        lviews: { 
            get: function () { return this.$t('views.userListPanel'); } 
        },        
		firstItem(){
			return this.totalItems? (this.page-1)*this.itemsPerPage+1: 0
		},
        lastItem(){
			if(this.totalItems){
				return this.totalItems< this.page*this.itemsPerPage? this.totalItems: this.page*this.itemsPerPage
			}
			return 0
        },
    },
    watch: {
        search: async function() {
            if (!this.preventUpdate) {
                if (this.searchTimeout != null) {
                    clearTimeout(this.searchTimeout);
                }
                var that = this;
                this.searchTimeout = setTimeout(async function(){
                    await that.updatePageData();
                }, 500);
            }
            else {
                this.preventUpdate = false;
            }
            this.updateUsersCacheStore()
        },
        options: {
            handler: async function() {
                if (!this.dataTableLoading) {
                    await this.updatePageData();
                }
                this.updateUsersCacheStore()
            },
            deep: true
        }
    },
    methods: {
        deleteUserCancel () {
            this.deleteUserDialog.visible = false;
            this.deleteUserDialog.id = null;
            this.deleteUserDialog.userName = null;
        },
        async deleteUserSave () {
            if (this.deleteUserDialog.id != null && this.deleteUserDialog.id != undefined) {
                this.deleteUserDialog.loading = true;

                try {
                    await axios({
                        url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/users/" + this.deleteUserDialog.id+"/tenant/",
                        method: "DELETE",
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                        }
                    });

                    this.$emit('display-success', this.lviews.deleteUserSuccess);

                    // update list
                    if (!this.dataTableLoading) {
                        await this.updatePageData();
                    }

                } catch (error) {
                    this.$emit("set-state", "default");
                    if (typeof(error.response) === 'undefined'){
                        this.$emit('display-error', this.lviews.undefinedError);
                        return;
                    }
                    else {
                        switch(error.response.status) {
                            case 400:
                                this.$emit('display-error', this.lviews.invalidRequestData);
                                break;
                            case 401:
                                this.$router.push("/login?next=/user-list");
                                break;
                            case 403:
                                this.$emit('display-error', this.lviews.youDoNotHavePriviledgesToThisAction);
                                break;    
                            case 404:
                                this.$emit('display-error', this.lviews.couldNotFindUserToDelete);
                                break;
                            case 429:
                                this.$emit('display-error', this.lviews.tooManyRequestsSent);
                                break; 
                            case 500:
                                this.$emit('display-error', this.lviews.serverError);
                                break;
                            default:
                                this.$emit('display-error', this.lviews.errorOccurredThatsAllWeKnow);
                                break;       
                        }
                    }
                }

                this.deleteUserDialog.visible = false;
                this.deleteUserDialog.id = null;
                this.deleteUserDialog.userName = null;
                this.deleteUserDialog.loading = false;
            }
        },
        /**
         * Opens dedicated to create new / edit user dialog.
         */
        createNewUser: function() {
            this.userDialogUserId = null;
            this.userDialogUserData = {};
            this.$nextTick(() => { this.$refs.userDialog.openDialog(); });
        },

        /**
         * Opens dedicated to user edit dialog.
         * @param user User data object to edit. Its structure is presented below
         */
        editUserRow: function(user) {
            let originalUserData = this.dataTablesItemsSourceData.find(x => { return x.auth_user.id == user.userId; });
            this.userDialogUserId = user.userId;
            this.userDialogUserData = { ...user, ...originalUserData };
            this.$nextTick(() => { this.$refs.userDialog.openDialog(); });
        },

        /**
         * Sends API request to server (in order to send activation account mail to user)
         * @param user - adapted user data. Its structure is presented below
         * 
         * {
         *      username: {String},
         *      name: {String},
         *      birthDate: {String},
         *      role: {String},
         *      dataAccess: {String},
         *      status: {Boolean},
         *      statusText: {String},
         *      lastLogin: {String},
         *      options: {Array of objects}
         * }
         */

        sendActivationMail: async function(user) {
            let response = null;

            this.$emit("set-state",  "LOADING_OVERLAYER", this.lviews.sendingEmail);
            try {
                response = await axios({
                    url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/users/" + user.userId+"/user-activation-mail/",
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    }
                });
            } catch (error) {
                this.$emit("set-state",  "DEFAULT");
                if (typeof(error.response) === 'undefined'){
					this.$emit('display-error', this.lviews.undefinedError);
					return;
				}
                else {
                    switch(error.response.status) {
                        case 400:
                            this.$emit('display-error', "Invalid user  identifier");
                            break;
                        case 401:
                            this.$router.push("/login?next=/user-list");
                            break;
                        case 403:
                            this.$emit('display-error', this.lviews.youDoNotHavePriviledgesToThisAction);
                            break;
                        case 429:
                            this.$emit('display-error', this.lviews.tooManyRequestsSent);
                            break;
                        case 423:
                            this.$emit('display-error', this.lviews.couldNotSendMailServerBusy);                            
                            break;
                        case 500:
                            this.$emit('display-error', this.lviews.couldNotSendMail);                            
                            break;
                        default:
                            this.$emit('display-error', this.lviews.errorOccurredThatsAllWeKnow);
                            break;
                    }
                }
            }

            if (response != null) {
                user.emailStatus=1
                user.emailStatusText="Email sent"
                this.$emit("set-state",  "DEFAULT");
                this.$emit("display-success",  this.lviews.mailSent);
            }
        },
        changeUserActiveStatus: async function() {
            let response = null;
            this.userActiveStatusChangeDialog.visible = false;
            if (this.userActiveStatusChangeDialog.user.blocked) {
                this.$emit("set-state",  "LOADING_OVERLAYER", this.lviews.unblockingAccountInProgress);
            }
            else {
                this.$emit("set-state",  "LOADING_OVERLAYER", this.lviews.blockingAccountInProgress);
            }

            try {
                response = await axios({
                    url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/users/" + this.userActiveStatusChangeDialog.user.userId+"/tenant/",
                    method: "PATCH",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'is_active': this.userActiveStatusChangeDialog.user.blocked ? true : false
                    }
                });
            } catch (error) {
                response = null;
                this.$emit('set-state', 'default');
                if (typeof(error.response) === 'undefined'){
					this.$emit('display-error', this.lviews.undefinedError);
					return;
				}
                else{
                    switch(error.response.status) {
                        case 400:
                            this.$emit('display-error', this.lviews.invalidRequestData);
                            break;
                        case 401:
                            this.$router.push("/login?next=/user-list");
                            break;
                        case 403:
                            this.$emit('display-error', this.lviews.youDoNotHavePriviledgesToThisAction);
                            break;
                        case 404:
                            if (this.userActiveStatusChangeDialog.user.blocked) {
                                this.$emit('display-error', this.lviews.couldNotFindUserToUnblock);
                            }
                            else {
                                this.$emit('display-error', this.lviews.couldNotFindUserToBlock);
                            }
                            break;
                        case 429:
                            this.$emit('display-error', this.lviews.tooManyRequestsSent);
                            break;
                        case 500:
                            this.$emit('display-error', this.lviews.serverError);
                            break;
                        default:
                            this.$emit('display-error', this.lviews.errorOccurredThatsAllWeKnow);
                            break;
                    }
                }
            }
            this.$emit('set-state', 'default');

            if (response != null) {
                this.updatePageData()
            }
        },

        /**
         * Opens modify user block status dialog
         */
		
        openModifyBlockedUserStatusDialog: async function(user) {
            if (user.blocked) {
                this.userActiveStatusChangeDialog.title = "Unblock user";
                this.userActiveStatusChangeDialog.content = "Are you sure you want to unblock user "+user.name +" ("+user.username+")?"
            }
            else {
                this.userActiveStatusChangeDialog.title = "Block user";
                this.userActiveStatusChangeDialog.content = "Are you sure you want to block user "+user.name +" ("+user.username+")?"
            }

            this.userActiveStatusChangeDialog.user = user;
            this.userActiveStatusChangeDialog.visible = true;
        },

        /** 
         * Determine user permissions and assign options.
         * @param isUserActivated - {Boolean} is this user account activated.
         * @param userBlocked {Boolean} Information whether specific user is blocked or not.
         * */ 
        checkUserPermissions: function(isUserActivated, userBlocked) {
            let optionsToAssign = [];
            let tmpOptions = [];
            // let usersPermissions = localStorage.getItem("permissions");
            // if (usersPermissions.includes("fob.admin_change")) {
                tmpOptions.push(
                    { icon: "mdi-border-color", title: "Edit data", action: this.editUserRow, disabled: !this.permissions.change },
                    { icon: "mdi-email-outline", title: "Send activation mail", action: this.sendActivationMail, disabled: isUserActivated},
                    // { icon: "mdi-lock", title: "{L Zresetuj hasło }", action: "" , disabled: false},
                );
                // More options
                if (userBlocked){
                    tmpOptions.push(
                        { icon: "mdi-replay", title: "Unblock", action: this.openModifyBlockedUserStatusDialog, disabled: !this.permissions.change},
                    )
                }
                else{
                    tmpOptions.push(
                        { icon: "mdi-replay", title: "Block", action: this.openModifyBlockedUserStatusDialog, disabled: !this.permissions.change},
                    );
                }    
            // }
            // if (usersPermissions.includes('fob.admin_delete')) {
                tmpOptions.push(
                    // { icon: "mdi-delete", title: "Delete", action: this.openDeleteUserDialog, disabled: (userDeletable && this.permissions.delete) ? false : true},
                    { icon: "mdi-delete", title: "Delete", action: this.openDeleteUserDialog, disabled: this.permissions.delete? false : true},
                );                
            // }
            optionsToAssign.push(
                tmpOptions
            )
            return optionsToAssign;
        },
        // fetches users data from server
        updatePageData: async function() {
            let userListData = await this.fetchUserListData();
            if (userListData) {
                this.adaptUserListData(userListData);
                this.dataTableLoading = false;
            }

        },

        openDeleteUserDialog (user) {
            this.deleteUserDialog.visible = true;
            this.deleteUserDialog.id = user.userId;
            this.deleteUserDialog.userName = user.name;
        },

        /**
         * Updates page number
         * @param newPageNumber - {Number} new page number
         */
        updatePageNumber: async function(newPageNumber) {
            this.options.page = newPageNumber;
            if (!this.dataTableLoading) {
                await this.updatePageData();
            }
        },

        /**
         * Updates items per page number
         * @param newPageSize - {Number} new items per page number
         */
        updatePageSize: async function(newPageSize) {
            this.options.itemsPerPage = newPageSize;
            if (!this.dataTableLoading) {
                await this.updatePageData();
            }
        },

        openModifyDataTableColumnsDialog: function() {
            this.dataTableHeadersSelected = [];
            for(var i = 0; i < this.dataTablesHeadersRendered.length; i++) {
                this.dataTableHeadersSelected.push(this.dataTablesHeadersRendered[i].id);
            }

            this.modifyDataTablesColumnsDialog = true;
        },
        updateDataTablesColumns: function(){
            this.dataTablesHeadersRendered = [];
            this.dataTableHeadersSelected.sort((a, b) => { return a < b ? -1 : 1; });
            for(var i = 0; i < this.dataTableHeadersSelected.length; i++){
                this.dataTablesHeadersRendered.push(
                    this.dataTablesHeaders[ this.dataTableHeadersSelected[i] ]
                );
            }
            this.updateUsersCacheStore()
        },

        /**
         * Chooses color for user's status.
         * @param status {Boolean} Boolean flag whether user account is activated or not.
         * @param blocked {Boolean} Boolean flag whether user account is blocked (is_active flag set to 0) or not.
         * @returns String with color to render.
         */
        getColor (status, blocked) {
            if (blocked) {
                return 'grey';
            }
            if (status) return 'success';
            else return 'red';
        },

        getEmailColor(status) {
            // 0 Unsent
            // 1 Sent
            // 2 Error
            // default 0
            if (status == 2) {
                return 'red';
            } else if (status == 1) {
                return 'success';
            } else {
                return 'grey';
            }
        },

        fetchUserListData: async function() {
            this.dataTableLoading = true;
            let params = {
				'page': this.options.page,
				'per_page': this.options.itemsPerPage,
				// 'order_by': this.getSortType() //do przeniesienia funkcja z Workers
			}
			// add search value to query params
            if (this.search != null && this.search != "") {
                params.personal_data_search=this.search
            }

            // add selected status to query params
            if (this.selectedStatus != null && this.selectedStatus!=-1) {
				params.status = this.selectedStatus
            }

            //add selected role to query params
			if(this.dataSharingRoleSelected!=null && this.dataSharingRoleSelected!=-1 ||this.roleSelected!=null && this.roleSelected!=-1){
				params.groups = this.setGroupsParam()
			}

            let responsePromise = axios({
                method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/users",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
                params: params
            });
            let response = null;
            try {
                response = await responsePromise;
            } catch (error) {
                this.dataTableLoading = false;
                if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}
				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
                        this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
            }
            return response.data;
            

            // // handling sorting datatable columns
            // if (this.options.sortBy != null && this.options.sortBy.length > 0) {
            //     switch(this.options.sortBy[0]) {
            //         case "birthDate":
            //             apiLink += "&sort_by=1";
            //             break;
            //         case "status":
            //             apiLink += "&sort_by=6";
            //             break;
            //         case "lastLogin":
            //             apiLink += "&sort_by=7";
            //             break;
            //         case "email":
            //             apiLink += "&sort_by=8";
            //             break;
            //         case "emailStatus":
            //             apiLink += "&sort_by=9";
            //             break;
            //     }

            //     if (this.options.sortDesc[0]) {
            //         apiLink += "&sort_desc=1";
            //     }
            //     else {
            //         apiLink += "&sort_desc=0";
            //     }
            // }

            // // handling role select
            // if (this.roleSelected != null && this.roleSelected != -1) {
            //     apiLink += ("&role=" + this.roleSelected.toString());
            // }

            // // handling data sharing role select
            // if (this.dataSharingRoleSelected != null && this.dataSharingRoleSelected != -1) {
            //     apiLink += ("&ds_role=" + this.dataSharingRoleSelected.toString());
            // }
        },

        adaptUserListData: async function(userListData) {
            this.activeUsersNumber = userListData.active_users_number;
            this.inactiveUsersNumber = userListData.inactive_users_number;

            this.usersNumber = userListData.users_overall;
            // this.administratorsNumber = userListData.administrators_overall;
            this.dataTablesItems = [];

            this.serverSideTotalItemsNumber = userListData.total;
			this.totalItems=userListData.total

            let rolesData = await this.fetchUserRoles();

            if (rolesData != null) {
                // assigning user roles select items
                this.roles = [];
                this.roles.push({
                    id: -1,
					name: "All"
                    // name: this.lviews.all
                });

                // adding filtering for simple roles (module groups)
                for (let i = 0; i < rolesData.roles.length; i++) {
                    this.roles.push({
                        id: rolesData.roles[i].id,
                        name: rolesData.roles[i].name
                    });
                }

                this.dataSharingRoles = [];
                this.dataSharingRoles.push({
                    id: -1,
                    // name: this.lviews.all
					name: "All"
                });
                // assigning filtering items for data sharing roles
                for (let i = 0; i < rolesData.data_sharing_roles.length; i++) {
                    this.dataSharingRoles.push({
                        id: rolesData.data_sharing_roles[i].id,
                        name: rolesData.data_sharing_roles[i].name
                    });
                }
                this.dataAccessData = rolesData.data_sharing_roles;
                this.userRolesData = rolesData.roles;
            }
            else { // could not fetch roles data => error will be displayed ()
                return;
            }

            this.dataTablesItemsSourceData = [];
            userListData.items.forEach(item => {
                this.dataTablesItemsSourceData.push(item);

                let optionsToAssign = this.checkUserPermissions(item.auth_user.account_activated, !item.auth_user.is_active_tenant);
                let newRoles = [], newRolesIds = [];
                let userDataSharingRoles = [], userDataSharingRolesIds = [];
                for(var i = 0; i < item.auth_user.groups.length; i++){
                    // getting user roles 
                    for(var j = 0; j < rolesData.roles.length; j++){
                        if (item.auth_user.groups[i] == rolesData.roles[j].id) {
                            newRoles.push(rolesData.roles[j].name);
                            newRolesIds.push(rolesData.roles[j].id);
                        }
                    }

                    // getting user data sharing roles
                    for (let j = 0; j < rolesData.data_sharing_roles.length; j++) {
                        if (item.auth_user.groups[i] == rolesData.data_sharing_roles[j].id) {
                            userDataSharingRoles.push(rolesData.data_sharing_roles[j].name);
                            userDataSharingRolesIds.push(rolesData.data_sharing_roles[j].id);
                        }
                    }
                }
                if (newRoles.length > 0) {
                    newRoles = newRoles.join(", ");
                } else {
                    // newRoles = this.lviews.lackOfData;
                }

                if (userDataSharingRoles.length > 0) {
                    userDataSharingRoles = userDataSharingRoles.join(", ");
                } else {
                    // userDataSharingRoles = this.lviews.lackOfData;
                }

                var active = '';
                if (item.auth_user.account_activated){
                    active = true;
                }
                else {
                    active = false;
                }
                let statusText = "";
                if (!item.auth_user.is_active_tenant) {
                    statusText="Blocked"
                }
                else {
                    statusText=active ? "Active" : "Inactive"
                }

                let emailStatusText;
                let emailStatus = item.auth_user.sent_email;

                if (emailStatus == 2) {
                    emailStatusText = "Error";    
                } else if (emailStatus == 1) {
                    emailStatusText = "Email sent";
                } else {
                    emailStatusText = "Email not sent";    
                }

                // get error email message
                let emailStatusErrorText = item.auth_user.email_error;

                if (emailStatusErrorText != null && emailStatus == 2) {
                    if (emailStatusErrorText.toLowerCase().trim() == 'smtp server refused connection') {
                        emailStatusErrorText = 'this.lviews.emailStatusErrorConnection;    '
                    } else if (emailStatusErrorText.toLowerCase().trim() == 'smtp server refused message') {
                        emailStatusErrorText = 'this.lviews.emailStatusErrorMessage; '   
                    } else if (emailStatusErrorText.toLowerCase().trim() == 'smtp server refused recipients') {
                        emailStatusErrorText = 'this.lviews.emailStatusErrorRecipients;  '   
                    } else if (emailStatusErrorText.toLowerCase().trim() == 'smtp server refused sender') {
                        emailStatusErrorText = 'this.lviews.emailStatusErrorSender; '     
                    } else if (emailStatusErrorText.toLowerCase().trim() == 'smtp server returned invalid response') {
                        emailStatusErrorText = 'this.lviews.emailStatusErrorInvlidResponse;'   
                    } else if (emailStatusErrorText.toLowerCase().trim() == 'smtp server closed connection') {
                        emailStatusErrorText = 'this.lviews.emailStatusErrorCloseConn;     '
                    } else if (emailStatusErrorText.toLowerCase().trim() == 'smtp server returned an unrecognized exception') {
                        emailStatusErrorText = 'this.lviews.emailStatusErrorException;'   
                    } else {
                        emailStatusErrorText = 'this.lviews.emailStatusErrorDefault;'
                    }
                } else {
                    emailStatusErrorText = null;    
                }

                this.dataTablesItems.push(
                    {
                        userId: item.auth_user.id,
                        username: item.auth_user.username,
                        name: item.last_name + " " + item.first_name,
                        birthDate: item.birth_date,
                        externalId: item.unique_personal_id,
                        role: newRoles,
                        rolesIds: newRolesIds,
                        dataAccess: userDataSharingRoles,
                        dataAccessIds: userDataSharingRolesIds,
                        status: active,
                        statusText: statusText,
                        lastLogin: item.auth_user.last_login ? item.auth_user.last_login : '-',
                        login: item.auth_user.username,
                        email: item.email,
                        emailStatus: emailStatus,
                        emailStatusText: emailStatusText,
                        emailError: emailStatusErrorText,
                        comment: item.comment,
                        deletable: item.allow_delete_worker,
                        blocked: !item.auth_user.is_active_tenant,
                        options: optionsToAssign,
                        worker: item.worker_id,
                    }
                );
            });
        },

		nextPage(){
			if(this.lastItem<this.totalItems){
				this.page++
				this.fetchUserListData()
			}
		},
		formerPage () {
			if(this.firstItem>1){
				this.page--
				this.fetchUserListData()
			}
		},

        fetchUserRoles: async function(){
            let apiLink = appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/roles";
            let responsePromise = axios({
                method: "GET",
                url: apiLink,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            });
            let response = null;
            try {
                response = await responsePromise;
            } catch (error) {
                if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}
				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
                        this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
            }
            return response.data;
        },

        setGroupsParam(){
            let param=[]
            if(this.dataSharingRoleSelected!=null && this.dataSharingRoleSelected!=-1){
                param.push(this.dataSharingRoleSelected)
            }
            if(this.roleSelected!=null && this.roleSelected!=-1){
                param.push(this.roleSelected)
            }
            return param.toString()
        },

        updateUsersCacheStore(){
			let data = {
				type: 'updateUsersCache',
				page: this.options.page,
				itemsPerPage: this.options.itemsPerPage,
				search: this.search,
				dataAccess: this.dataSharingRoleSelected,
				status: this.selectedStatus,
				columnsVisible: this.dataTableHeadersSelected
			}
			this.$store.commit(data)
		},

        openAddUser(){
            this.$router.push('/add-user');
        },

        // prop methods for user-dialog

        /**
         * Shows loading overlayer when sending request
         */
        showOverlayer: function(message) {
            this.$emit("set-state", "LOADING_OVERLAYER", message);
        },

        /**
         * Hides loading overlayer after receiving response
         */
        hideOverlayer: function() {
            this.$emit("set-state", "DEFAULT");
        }
    }
}
</script>
<style>
    .worker-document-menu-option {
        background-color: white;
    }
    .worker-document-menu-option:hover {
        background-color: #e4e4e4;
    }

    .user-list-search-field {
        width: 260px;
        display: inline-block !important;
        padding-left: 10px;
        padding-right: 10px;
    }
    /* .user-list-status-select-wrapper {
        width: 700px;
    } */
    .user-list-status-select-field {
        width: 200px;
        display: inline-block !important;
        padding-left: 10px;
        padding-right: 10px;
    }

    @media only screen and (max-width: 530px) {
        .user-list-search-field {
            width: 100%;
        }
        .user-list-status-select-wrapper {
            width: 100%;
        }
        .user-list-status-select-field {
            width: calc(100% - 50px);
        }
    }
    .users-summary-label {
        font-size: 14px;
        color: gray;
        padding-left: 14px;
        padding-bottom: 15px;
        width: 100%;
    }
</style>
<style scoped>
    .document-name-cut-when-too-long {
        max-width: 170px;
        overflow: hidden; 
        white-space: nowrap; 
        text-overflow: ellipsis;
    }
    .data-tables-header-field {
    background-color: rgb(83,241,107)!important;
    color: rgb(62, 51, 51) !important;
    }
    .add-new-users-button {
        position: fixed !important;
        bottom: 25px !important;
        right: 25px !important;
    }
</style>